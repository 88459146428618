/** Accounts view header **/

.view-primary-header {
  background: var(--light-background);
  border: 1px solid var(--light-border);
  min-height: max-content;
  @apply shadow-md py-4 px-5 my-3 rounded-md;

  /** Mat card **/
  .mat-mdc-card-content {
    padding: 16 !important;
  }
}

.view-primary-header-item {
  margin-right: 20px;
}

.view-primary-header.warning-icon {
  margin-left: 10px;
  font-size: 20px;
}

.search-input-row {
  display: flex;
  flex-wrap: wrap;
  @apply sm:justify-start sm:items-center xl:justify-between xl:items-center gap-2;
}

.search-button-row {
  display: flex;
  flex-direction: row;
  @apply justify-end items-center flex-grow flex-shrink basis-0;
}

/** Mat dialog **/
.mat-dialog-question {
  font-family: Roboto-Medium;
  color: var(--color-black-light);
  font-size: 1.8rem;
  text-align: center;
  @apply my-3;
}

/** Text info for cards and views **/
.detail-label {
  font-family: Roboto-Regular;
  color: var(--color-darker);
  font-size: 12px;
  @apply my-2;
}

.detail-value {
  font-family: Roboto-Medium;
  color: var(--color-darkest);
  font-size: 18px;
  @apply my-2;
}

@media (max-width: 960px) {
  .detail-label {
    font-family: Roboto-Regular;
    color: var(--color-darker);
    font-size: 11px;
    @apply my-1;
  }

  .detail-value {
    font-family: Roboto-Medium;
    color: var(--color-darkest);
    font-size: 15px;
    @apply my-2;
  }
}

/** Mat Cards **/
.mat-card-title-custom {
  font-size: 16px !important;
  font-family: Roboto-Medium !important;
  color: var(--primary-A600) !important;
  margin-bottom: 0px !important;
}

.mat-card-home-view {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  padding: 16px 10px;
  margin: 0% !important;
}

.mat-card-container-view {
  border: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  padding: 16px 24px !important;
}

.account-summary-container {
  border-radius: 0px !important;
  background-color: var(--color-lightest) !important;
  padding: 16px !important;
  margin-bottom: 1.5rem !important;
  border: none !important;
  box-shadow: none !important;
  border: 1px solid var(--color-lighter) !important;
}

/** HOME PAGE  **/

.header-logo-img {
  z-index: 2;
  width: auto;
  height: 55px;
  display: block;
  position: relative;
  padding-left: 10px;
  image-rendering: auto;
  transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -webkit-transition: opacity 0.4s ease-in-out;
  cursor: pointer;
}

.mepco-home-text {
  font-family: Roboto-Light;
  font-size: 16px;
  color: var(--dark-primary-text);
  margin-top: 0px;
  margin-bottom: 16px;
  line-height: 1.4rem !important;
}

.mepco-home-text-title {
  font-family: Roboto-Medium;
  font-size: 2.5rem;
  color: var(--home-title-text);
  margin-top: 0px;
  margin-bottom: 16px;
}

.mepco-home-text-subtitle {
  font-family: Roboto-Regular;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--home-subtitle-text);
  margin-top: 0px;
  margin-bottom: 10px;
}

.mepco-home-text-headers {
  font-family: PTSans-NarrowBold;
  font-size: 1rem;
  margin-top: 0px;
}

.main-container > .sidenav-menu {
  min-width: 200px;
  padding-top: 69px;
}

.main-container > .sidenav-menu mat-nav-list .mat-list-item {
  height: 38px;
}

.main-container > .sidenav-menu mat-nav-list .label {
  padding-left: 10px;
  font-size: 14px;
}

/* Errors styles */
.error-mat-dialog .mat-dialog-container {
  background-color: var(--warn-100);
}

.disable-payment {
  font-size: 16px;
  font-family: 'Roboto-Regular';
  color: var(--light-primary-text);
}

@media (max-width: 768px) {
  .header-logo-img {
    height: 30px;
    padding-left: 0px;
  }
}

@media (max-width: 1024px) {
  .mepco-home-text {
    font-size: 1rem !important;
  }

  .mepco-home-text-title {
    font-size: 2rem !important;
  }

  .mepco-home-text-subtitle {
    font-size: 1.5rem !important;
  }
}

.coveragex-header-logo-img {
  z-index: 2;
  width: auto;
  height: 155px;
  display: block;
  position: relative;
  padding-left: 10px;
  image-rendering: auto;
  transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -webkit-transition: opacity 0.4s ease-in-out;
  cursor: pointer;
}

@media (max-width: 660px) {
  .coveragex-header-logo-img {
    height: 130px !important;
    padding-left: 0px;
  }
}

/** Material expansion panel **/
.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  height: 24px;
  width: 24px;
  background-image: url('/assets/icons/keyboard_arrow_down.svg');
  background-size: cover;
  border: none;
  border-width: 0 !important;
  transform: rotate(0) !important;
}

.input-icon {
  position: relative;
  top: 8px;
  left: 5px;
  transform: scale(0.6);
  color: var(--color-neutral-dark);
}
